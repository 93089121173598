import PropTypes from "prop-types";

import classes from "./Button.module.css";

const Button = ({ color, text, onClick }) => {
    const textColor = color !== "yellow" ? "white" : "black";

    return (
        <button
            onClick={onClick}
            style={{ color: textColor, backgroundColor: color }}
            className={classes.btn}
        >
            {text}
        </button>
    );
};

Button.defaultProps = {
    color: "steelblue",
};

Button.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
};

export default Button;
