import React, { useState, useEffect, useRef } from "react";
import Loader from "react-loader-spinner";

// TODO: do we need this? It adds support for many browsers.
//https://www.npmjs.com/package/resize-observer-polyfill
import ResizeObserver from "resize-observer-polyfill";

import moment from "moment";

import classes from "./GenericChart.module.css";

import {
    select,
    line,
    //curveCardinal,
    // axisBottom,
    // axisLeft,
    scaleLinear,
    scaleBand,
} from "d3";

import * as d3 from "d3";

// import { getXScale, getYScale, drawAxis, drawLine, animateLine } from "../../../scripts/chartUtils";

// TODO:
// React.js Hooks Crash Course
// https://www.youtube.com/watch?v=-MlNBTSg_Ww
// https://observablehq.com/@d3/learn-d3
// https://blog.griddynamics.com/using-d3-js-with-react-js-an-8-step-comprehensive-manual/

const useResizeObserver = (ref) => {
    const [dimensions, setDimensions] = useState(null);
    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            // console.log(entries);
            // set resized dimensions here
            entries.forEach((entry) => {
                setDimensions(entry.contentRect);
            });
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);
    return dimensions;
};

const getTickValue = (days, d) => {
    //console.log("getTickValue: days, d", days, d);
    if (d === "") {
        return "";
    }

    if (days > 2) {
        //return moment(new Date(d)).format("DD.MM.YYYY");
        return d;
    } else {
        d = Number(d);
        const t = moment(new Date(d)).format("HH") + ":00";
        if (t === "00:00") {
            return moment(new Date(d)).format("DD.MM.YYYY");
        } else {
            return t;
        }
    }
};

// Using React (Hooks) with D3 – [06] Responsive Chart Components with ResizeObserver
// https://www.youtube.com/watch?v=a4rstx9Pz2o&list=PLDZ4p-ENjbiPo4WH7KdHjh_EMI7Ic8b2B&index=7

const GenericChart = (props) => {
    const svgRef = useRef();
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);

    // const margin = {
    //     left: 0,
    //     right: 0,
    //     top: 0,
    //     bottom: 0,
    // };
    const marginLeft = 0;
    const marginRight = 0;
    const marginTop = 0;
    const marginBottom = 10;

    const chartType = props.chartType;

    const [showLoader, setShowLoader] = useState(true);

    // Called when DOM has been rendered or when elements in the dependency list change.
    useEffect(() => {
        // dimensions is null at first
        if (!dimensions) {
            return;
        }

        let a = undefined;

        // get an array with the the object from the data array whose name equals chartType
        let f = props.data.filter((m) => {
            return m.name === chartType;
        });
        if (f.length > 0) {
            a = f[0].values;
        }

        if (!a) return;

        setShowLoader(false);

        const svgEl = select(svgRef.current);

        // Clear svg content before adding new elements
        svgEl.selectAll("*").remove();
        // svgEl.selectAll("path").remove();
        // svgEl.selectAll("circle").remove();

        const { width, height } = dimensions;
        // const svgWidth = width + margin.left + margin.right;
        // const svgHeight = height + margin.top + margin.bottom;

        // let data = a.yValues; //a.map((o) => o.value);
        let data = a.map((o) => Object.values(o));

        const delta = props.endDate - props.startDate;
        const ONE_DAY = 1000 * 60 * 60 * 24;
        const days = Math.ceil(delta / ONE_DAY);

        let ticksData = a.map((o) => Object.keys(o)[0]);

        let strokeColor = "#087aad";

        let maximumYValue = 100;
        if (data.length > 0) {
            maximumYValue = 1.1 * Math.max(...data);
        }

        // Remove some of the ticks if we have too many.
        const numberOfTicks = ticksData.length;

        let increment = 1;

        // xt determines how many ticks we have on the x axis
        let xt = 10;
        if (days <= 2) {
            xt = 12;
        }

        increment = Math.round(numberOfTicks / xt);
        if (increment < 1) {
            increment = 1;
        }

        let newTicksData = [];
        for (let i = 0; i < ticksData.length; i += 1) {
            let v = "";
            if (i % increment === 0) {
                v = ticksData[i];
            }
            if (days <= 2) {
                v = getTickValue(days, v);
            }
            newTicksData.push(v);
        }
        ticksData = newTicksData;

        // scales
        const xScale = scaleBand()
            //.domain([0, 1, 2, 3, 4, 5, 6])
            .domain(data.map((value, index) => index))
            .range([0, dimensions.width])
            .padding(1); // make bar width smaller

        const yScale = scaleLinear().domain([0, maximumYValue]).range([dimensions.height, 0]);

        //const svg = svgEl.append("g").attr("transform", `translate(${margin.left},${margin.top})`);
        const svg = svgEl.append("g").attr("transform", `translate(0,0)`);
        // Add X grid lines with labels

        const xAxisScale = scaleBand()
            //.domain([0, 1, 2, 3, 4, 5, 6])
            .domain(ticksData.map((value, index) => index))
            .range([0, dimensions.width])
            .padding(1); // make bar width smaller

        // const marginBottom = 10;
        const xAxis = d3
            .axisBottom(xAxisScale)
            .ticks(data.length)
            .tickFormat((index) => ticksData[index])
            //.tickSize(-height + margin.bottom);
            .tickSize(0 + marginBottom);
        const xAxisGroup = svg.append("g").attr("transform", `translate(0, ${height})`).call(xAxis);
        xAxisGroup.select(".domain").remove();
        xAxisGroup.selectAll("line").attr("stroke", "rgba(0, 0, 0, 0)");
        xAxisGroup.selectAll("text").attr("opacity", 1.0).attr("color", "#394855").attr("font-size", "0.75rem");

        // Add Y grid lines with labels
        const yAxis = d3
            .axisLeft(yScale)
            .ticks(5)
            .tickSize(-width)
            //.tickFormat((val) => `${val}%`);
            .tickFormat((val) => `${val}`);
        const yAxisGroup = svg.append("g").call(yAxis);
        yAxisGroup.select(".domain").remove();
        yAxisGroup.selectAll("line").attr("stroke", "rgba(0, 0, 255, 0.2)");
        // yAxisGroup.selectAll("text").attr("opacity", 0.6).attr("color", "black").attr("font-size", "0.75rem");
        yAxisGroup.selectAll("text").attr("opacity", 1.0).attr("color", "#394855").attr("font-size", "0.75rem");

        const myLine = line()
            .x((value, index) => xScale(index))
            //.y(value => yScale(value))
            .y(yScale);
        // .curve(curveCardinal);

        // renders path element, and attaches
        // the "d" attribute from line generator above
        // strokeColor = "red";
        svgEl
            .selectAll(".line")
            .data([data])
            .join("path")
            .attr("class", "line")
            .attr("d", (value) => myLine(value))
            .attr("stroke-width", 3)
            .attr("fill", "none")
            .attr("stroke", strokeColor);

        // If drawing a daily chart, draw circles for each day.
        // When showing minute data, (for 2 days or less), don't draw circles.
        if (days > 2) {
            svgEl
                .selectAll("circle")
                .data(data)
                .join("circle")
                .attr("cx", (value, index) => xScale(index))
                .attr("cy", (value, index) => yScale(value))
                .attr("r", 4)
                .attr("fill", strokeColor);
        }

        //console.log("svg selection", svg.selectAll("circle").data(data));
    }, [props, dimensions, chartType]); // dependency list, when data or dimensions change, call useEffect again

    return (
        <div className={classes.Chart_wrapper}>
            <div ref={wrapperRef} style={{ marginBottom: "2rem" }}>
                {showLoader && (
                    <div className={classes.Chart_loader}>
                        {" "}
                        <Loader type="Oval" color="#394855" height={300} width={300} />
                    </div>
                )}

                {!showLoader && (
                    <svg ref={svgRef} style={{ height: 400 }}>
                        <g className="container" transform={`translate(${marginLeft},${marginBottom})`} />
                    </svg>
                )}
            </div>
        </div>
    );
};

export default GenericChart;
