import React from "react";
import moment from "moment";
import classes from "./SelectedDateRangeHeading.module.css";

const SelectedDateRangeHeading = (props) => {
    const getDateRange = () => {
        if (moment(props.startDate).format("DD MM YYYY") === moment(props.endDate).format("DD MM YYYY")) {
            return moment(props.startDate).format("DD.MM.YYYY");
        } else {
            return moment(props.startDate).format("DD.MM.YYYY") + " - " + moment(props.endDate).format("DD.MM.YYYY");
        }
    };

    return (
        <div>
            <h2 className={classes.periodName}>{props.periodName}</h2>
            <p className={classes.dateRange}>{getDateRange()}</p>
        </div>
    );
};

export default SelectedDateRangeHeading;
