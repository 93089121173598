import React, { useEffect, useState } from "react";

import Loader from "react-loader-spinner";

import classes from "./ValueCard.module.css";

const ValueCard = (props) => {
    const [showLoader, setShowLoader] = useState(true);

    // instead of N/A show a spinner
    useEffect(() => {
        if (props.value === "N/A") {
            setShowLoader(true);
        } else {
            setShowLoader(false);
        }
    }, [props.value]);

    return (
        <div className={classes.resultItem}>
            {showLoader && (
                <div className={classes.loader}>
                    {" "}
                    <Loader type="Oval" color="#394855" height={30} width={30} />
                </div>
            )}
            {!showLoader && <div className={classes.amountNumber}>{props.value}</div>}

            <div className={classes.resultItemTitle}>{props.title}</div>
        </div>
    );
};

export default ValueCard;
